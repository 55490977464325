import React, { FC } from "react";
import { ContactUsForm } from "./ContactUs";
import { TopBar } from "./TopBar";
import { Home } from "./Home";
import { Routes, Route, useSearchParams, useLocation } from "react-router-dom";
import { FAQs } from "./FAQ";
import { Pricing } from "./Pricing";
import { TailwindDebug } from "./TailwindDebug";
import { Start } from "./Start";
import { Estimate } from "./Estimate";

export const Main: FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const debug = searchParams.get("debug")
    const location = useLocation();
    console.log(location.pathname);

    return (
        <div>
            {location.pathname !== '/start' && location.pathname !== '/estimate' && <TopBar />}
            {debug && <TailwindDebug />}
            <Routes>
                <Route>
                    <Route index element={<Home />} />
                    <Route path="faqs" element={<FAQs />} />
                    <Route path="pricing" element={<Pricing />} />
                    <Route path="start" element={<Start />} />
                    <Route path="estimate" element={<Estimate />} />
                </Route>
            </Routes>
            {location.pathname !== '/start' && location.pathname !== '/estimate' && <ContactUsForm />}
        </div>
    );
}
