import { FC } from "react";
import { Header } from "./Header";
import Ticker from 'react-ticker';

export const SupportedBrands: FC = () => {

    const getImageNumber = (index: number) => {
        // Get image number from index with min=1 and max=15 regardless of index
        return ((index + 1) % 15) || 15;
    }

    return (
        <div className="pt-16 pb-6">
            <Header
                className="text-center"
                text="Supported Brands"
            />
            <div className="mt-10 mx-10 text-center">
                <Ticker 
                    height={150}
                    speed={10   }    
                >
                    {({ index }) => {
                        const indexNumber = getImageNumber(index);
                        const image = `/images/brand-${(indexNumber).toString().padStart(2, '0')}.png`;
                        const alt = `Brand ${(indexNumber).toString().padStart(2, '0')}`;
                        return (
                            <div
                                key={`image_${index}`}
                                className="w-full flex justify-center items-center h-[100px] mx-[50px]"
                            >
                                <img
                                    className="w-[240px]"
                                    src={image}
                                    alt={alt}
                                />
                            </div>
                        )
                    }}
                </Ticker>
            </div>
        </div>
    );
}