import React, { FC, createRef } from "react";
import { useSelector } from "react-redux";
import { FAQs } from "./FAQ";
import { HowCanWeHelp } from "./HowCanWeHelp";
import { HowDoesItWork } from "./HowDoesItWork";
import { NoContracts } from "./NoContracts";
import { SaveThousands } from "./SaveThousands";
import { SupportedBrands } from "./SupportedBrands";
import { TypicalBusinessCanExpect } from "./TypicalBusinessCanExpect";
import { WhichBusinessesCanWeHelp } from "./WhichBusinessesCanWeHelp";

export const Home: FC = () => {
    const [clicked, setClicked] = React.useState(false);
    const global = useSelector((state: any) => state.global.variables)

    return (
        <div>
            <SaveThousands />
            <HowCanWeHelp />
            <NoContracts />
            <WhichBusinessesCanWeHelp />
            <TypicalBusinessCanExpect />
            <HowDoesItWork />
            <SupportedBrands />
            <FAQs short />
        </div>
    );
}
