import { FC } from "react";
import { Header } from "./Header";


export interface WhichBusinessesCanWeHelpColumnParams {
    imageLocation: string;
    header: string;
    text: string;
}

export const WhichBusinessesCanWeHelpColumn: FC<WhichBusinessesCanWeHelpColumnParams> = (props) => {
    const { imageLocation, header, text } = props;
    return (
        <div className="m-4 pt-10 pb-12 py-6 px-8 xl:w-1/3 text-center flex flex-col border-[#E2F0D9] border-2 rounded-[30px] bg-white flex-1">
            <img src={imageLocation} alt="fridge" className="h-40 self-center" />
            <p className="mt-6 text-3xl font-bold text-[#92D050]">{header.split(" ")[0]}<br/>{header.split(" ")[1]}</p>
            <p className="mt-6 text-xl text-black">{text}</p>
        </div>
    )
}

export const WhichBusinessesCanWeHelp: FC = () => {
    return (
        <div className="text-center xl:px-24 py-12 bg-[#F2F2F2]">
            <div className="flex flex-row justify-center items-center">
                <div className="p-10 hidden xl:inline">
                    <img src="/images/your-business.png" />
                </div>
                <div className="p-10 xl:text-left xl:mx-10">
                    <Header
                        text="Which businesses can we help?"
                    />
                    <p className="text-xl mt-10">
                    The Raasify energy solution aims to leverage advanced technology to reduce your business operational costs in an environmentally responsible way. Here are the types of businesses that we believe will find value in Raasify energy:
                    </p>
                </div>
            </div>

            <div className="flex flex-col xl:flex-row w-full px-6 pb-12">
                <WhichBusinessesCanWeHelpColumn
                    imageLocation="/images/open.png"
                    header="Daytime operations"
                    text="Grocery stores, Bottle shops, and other businesses that primarily operate during daytime hours can benefit from our real time power consumption and operational optimization solution."
                />
                <WhichBusinessesCanWeHelpColumn
                    imageLocation="/images/cooling.png"
                    header="Cooling systems"
                    text="Standalone commercial display fridges and vending machines contribute significantly to business energy and maintenance costs. Raasify energy solution can help reduce these."
                />
                <WhichBusinessesCanWeHelpColumn
                    imageLocation="/images/distributed.png"
                    header="Distributed locations"
                    text="Assets that are distributed in remote and unmonitored locations are difficult to manage. Raasify energy real time monitoring & alerting functions can reduce energy and overall running costs." />
            </div>
        </div>
    );
}
