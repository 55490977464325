import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Main } from "./components/Main";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export const AppContainer: FC = (props) => {
    const [widget, setWidget] = useState<any>(null);

    const global = useSelector((state: any) => state.global.variables)

    return (
        <>
            <Main />
            <ToastContainer />
        </>
    );
}
