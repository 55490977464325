import { FC, useState } from "react";
import { Header } from "./Header";
import { IconButton, Tooltip } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import { GradientButton } from "./Buttons";
import { cn } from "../util/util";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as React from 'react';

import ReCAPTCHA from 'react-google-recaptcha'
import { toast } from "react-toastify";
import { HourSelector } from "./HourSelector";
import { ErrorMessage } from "./ErrorMessage";
import { REACT_APP_SITE_KEY } from "../util/settings";
import { ConfirmDialog } from "./ConfirmDialog";

const BUTTON_SELECTED_CLASS = "bg-white border-blue-700 border-4";
const BUTTON_NOT_SELECTED_CLASS = "bg-white";

interface SelectedIF {
    name?: string;
    address?: string;
    fridge: boolean | undefined;
    vending: boolean | undefined;
    other: boolean | undefined;
    open?: string;
    close?: string;
    locations: number;
    wifi?: boolean | undefined;
    solution?: string;
    email?: string;
}

interface AssetSelectionProps {
    asset: string;
    icon: string;
    selected: boolean;
    onSelect: () => void;
}

export const AssetSelection: FC<AssetSelectionProps> = (props: AssetSelectionProps) => {
    const { asset, icon, selected, onSelect } = props;
    const bgClass = selected ? BUTTON_SELECTED_CLASS : BUTTON_NOT_SELECTED_CLASS;
    return (
        <div className={cn("h-28 w-36 xl:w-44 text-black text-sm p-4 font-normal flex items-center rounded-2xl cursor-pointer", bgClass)} onClick={onSelect}>
            <div className="hidden xl:flex">
                <img src={icon} className="object-cover cursor-pointer w-10" />
            </div>
            <label className="xl:ml-4 w-full text-center xl:text-left cursor-pointer">{asset}</label>
        </div>
    )
}

interface LocationSelectionProps {
    selected: number;
    onSelect: (value: number) => void;
}

export const LocationSelection: FC<LocationSelectionProps> = (props: LocationSelectionProps) => {
    const { selected, onSelect } = props;
    const bgClass1 = selected === 1 ? BUTTON_SELECTED_CLASS : BUTTON_NOT_SELECTED_CLASS;
    const bgClass2 = selected === 2 ? BUTTON_SELECTED_CLASS : BUTTON_NOT_SELECTED_CLASS;
    const bgClass3 = selected === 3 ? BUTTON_SELECTED_CLASS : BUTTON_NOT_SELECTED_CLASS;

    const baseClasses = "h-18 w-28 xl:w-36 text-black text-sm p-4 font-normal flex items-center rounded-2xl cursor-pointer";
    return (
        <div className="flex gap-2 xl:gap-12">
            <div className={cn(baseClasses, bgClass1)} onClick={() => onSelect(1)}>
                <div className="hidden xl:flex">
                    <img src="/images/icon-location.svg" className="w-4 xl:w-8 object-cover cursor-pointer" />
                </div>
                <label className="xl:ml-4 w-full text-center xl:text-left cursor-pointer">One</label>
            </div>
            <div className={cn(baseClasses, bgClass2)} onClick={() => onSelect(2)}>
                <div className="hidden xl:flex">
                    <img src="/images/icon-location.svg" className="w-4 xl:w-8 object-cover cursor-pointer" />
                </div>
                <label className="xl:ml-4 w-full text-center xl:text-left cursor-pointer">Two</label>
            </div>
            <div className={cn(baseClasses, bgClass3)} onClick={() => onSelect(3)}>
                <div className="hidden xl:flex">
                    <img src="/images/icon-location.svg" className="w-4 xl:w-8 object-cover cursor-pointer" />
                </div>
                <label className="xl:ml-4 w-full text-center xl:text-left cursor-pointer">3 +</label>
            </div>
        </div>
    )
}

interface YesNoSelectionProps {
    selected: boolean | undefined;
    onSelect: (value: boolean) => void;
}

export const YesNoSelection: FC<YesNoSelectionProps> = (props: YesNoSelectionProps) => {
    const { selected, onSelect } = props;
    const bgClassYes = selected === true ? BUTTON_SELECTED_CLASS : BUTTON_NOT_SELECTED_CLASS;
    const bgClassNo = selected === false ? BUTTON_SELECTED_CLASS : BUTTON_NOT_SELECTED_CLASS;

    const baseClasses = "h-18 w-28 xl:w-36 text-black text-sm p-4 font-normal flex items-center rounded-2xl cursor-pointer";
    return (
        <div className="flex gap-2 xl:gap-12">
            <div className={cn(baseClasses, bgClassYes)} onClick={() => onSelect(true)}>
                <div className="hidden xl:flex">
                    <img src="/images/icon-location.svg" className="w-4 xl:w-8 object-cover cursor-pointer" />
                </div>
                <label className="xl:ml-4 w-full text-center xl:text-left cursor-pointer">Yes</label>
            </div>
            <div className={cn(baseClasses, bgClassNo)} onClick={() => onSelect(false)}>
                <div className="hidden xl:flex">
                    <img src="/images/icon-location.svg" className="w-4 xl:w-8 object-cover cursor-pointer" />
                </div>
                <label className="xl:ml-4 w-full text-center xl:text-left  cursor-pointer">No</label>
            </div>
        </div>
    )
}

interface SolutionSelectionProps {
    selected: string | undefined;
    onSelect: (value: string) => void;
}

export const SolutionSelection: FC<SolutionSelectionProps> = (props: SolutionSelectionProps) => {
    const { selected, onSelect } = props;
    const bgClassMonitor = selected === "Monitor" ? BUTTON_SELECTED_CLASS : BUTTON_NOT_SELECTED_CLASS;
    const bgClassSaver = selected === "Saver" ? BUTTON_SELECTED_CLASS : BUTTON_NOT_SELECTED_CLASS;
    const bgClassOptimizer = selected === "Optimizer" ? BUTTON_SELECTED_CLASS : BUTTON_NOT_SELECTED_CLASS;

    const baseClasses = "h-24 w-[122px] xl:w-36 text-black text-sm p-4 font-normal flex flex-col items-center justify-center rounded-2xl cursor-pointer";
    return (
        <div className="flex gap-2 xl:gap-8">
            <div className={cn(baseClasses, bgClassMonitor)} onClick={() => onSelect("Monitor")}>
                <label className="cursor-pointer">Energy</label>
                <label className="mt-1 text-xl font-bold cursor-pointer">Monitor</label>
            </div>
            <div className={cn(baseClasses, bgClassSaver)} onClick={() => onSelect("Saver")}>
                <label className="cursor-pointer">Energy</label>
                <label className="mt-1 text-xl font-bold cursor-pointer">Saver</label>
            </div>
            <div className={cn(baseClasses, bgClassOptimizer)} onClick={() => onSelect("Optimizer")}>
                <label className="cursor-pointer">Energy</label>
                <label className="mt-1 text-xl font-bold cursor-pointer">Optimizer</label>
            </div>
        </div>
    )
}

interface PageProps {
    selected?: SelectedIF;
    setSelected?: (selected: SelectedIF) => void;
    onCancel?: () => void;
    onNext?: () => void;
    onBack?: () => void;
    setToken?: (token: string) => void;
    token?: string;
    debug?: boolean;
}

const Page1: FC<PageProps> = (props: PageProps) => {
    const { onCancel, onNext, selected, setSelected, debug } = props;
    const [errors, setErrors] = useState<any>({});

    if (!selected || !setSelected) {
        return <></>
    }

    const validate = () => {
        if (debug) return true;

        let ret = true;
        let errorRes = {};
        if (!selected.name) {
            errorRes = { ...errorRes, name: { message: "Please enter a business name" } };
            ret = false;
        }
        if (!selected.address) {
            errorRes = { ...errorRes, address: { message: "Please enter a business address" } };
            ret = false;
        }
        if (!selected.fridge && !selected.vending && !selected.other) {
            errorRes = { ...errorRes, assets: { message: "Please select at least one asset type" } };
            ret = false;
        }
        setErrors(errorRes);
        return ret;
    }

    return (
        <>
            <div className="flex w-full relative">
                <div className="w-[40%] px-10 py-10 flex-col justify-center items-center hidden xl:flex">
                    <div className="w-full flex flex-col items-center">
                        <img src="/images/raasify.svg" className="w-[220px]" />
                        <img src="/images/jar.png" className="mt-10 h-[80vh]" />
                    </div>
                </div>
                <div className="w-full xl:w-[60%] min-h-[100vh] h-full px-4 xl:px-20 py-10 bg-[#92D050] flex flex-col justify-center items-center">
                    <div className="w-full">
                        <div className="absolute right-4 top-4 text-white">1 of 3</div>
                        <div className="font-bold mb-6">
                            <Header
                                className="mb-8 text-white"
                                text="LET'S GET YOU SAVING"
                            />
                        </div>
                        <div className="text-white text-lg font-bold">
                            <p className="mb-4">What is your business name?</p>
                            <input
                                value={selected.name}
                                onChange={(e) => setSelected({ ...selected, name: e.target.value })}
                                type="text"
                                className="border-2 border-[#92D050] w-full h-10 rounded-md px-2 text-black" />
                            <ErrorMessage message={errors.name?.message} />
                        </div>
                        <div className="text-white text-lg font-bold">
                            <p className="mt-8 mb-4">What is your business address?</p>
                            <input
                                value={selected.address}
                                onChange={(e) => setSelected({ ...selected, address: e.target.value })}
                                type="text"
                                className="border-2 border-[#92D050] w-full h-10 rounded-md px-2 text-black" />
                            <ErrorMessage message={errors.address?.message} />
                        </div>
                        <div className="text-white text-lg font-bold">
                            <div className="flex mt-8 mb-4">
                                <p className="mt-1.5">Do you have any of the following?</p>
                                <Tooltip title="Standalone powered assets that can be turned off and on during their operational lifecycle.">
                                    <IconButton>
                                        <InfoIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <div className="flex grid-flow-row grid-cols-3 space-x-4">
                                <AssetSelection
                                    icon="/images/icon-fridge.png"
                                    asset="Refrigeration systems"
                                    selected={selected.fridge as any}
                                    onSelect={() => setSelected({ ...selected, fridge: !selected.fridge })}
                                />
                                <AssetSelection
                                    icon="/images/icon-fridge.png"
                                    asset="Vending machines"
                                    selected={selected.vending as any}
                                    onSelect={() => setSelected({ ...selected, vending: !selected.vending })}
                                />
                                <AssetSelection
                                    icon="/images/icon-other.png"
                                    asset="Other asset types"
                                    selected={selected.other as any}
                                    onSelect={() => setSelected({ ...selected, other: !selected.other })}
                                />
                            </div>
                            <ErrorMessage message={errors.assets?.message} />
                        </div>
                        <GradientButton
                            className="mt-16 float-right w-[120px] xl:w-[200px]"
                            onClick={() => {
                                if (validate()) onNext && onNext();
                            }}
                            text="Next"
                        />
                        <GradientButton
                            className="mt-16 w-[120px] xl:w-[200px]"
                            onClick={onCancel}
                            text="Cancel"
                        />
                    </div>
                </div>
            </div>

        </>
    );
};

const Page2: FC<PageProps> = (props: PageProps) => {
    const { onBack, onNext, selected, setSelected, debug } = props;
    const [errors, setErrors] = useState<any>({});

    if (!selected || !setSelected) {
        return <></>
    }

    const validate = () => {
        if (debug) return true;

        let ret = true;
        let errorRes = {};
        if (selected.locations === 0) {
            errorRes = { ...errorRes, locations: { message: "Please indicate how many locations the assets are installed at" } };
            ret = false;
        }
        if (selected.wifi === undefined) {
            errorRes = { ...errorRes, locations: { message: "Please indicate if all sites have WiFi Internet connectivity" } };
            ret = false;
        }
        setErrors(errorRes);
        return ret;
    }

    return (
        <>
            <div className="flex w-full relative">
                <div className="w-[40%] px-10 py-10 hidden xl:flex flex-col justify-center items-center">
                    <div className="w-full flex flex-col items-center">
                        <img src="/images/raasify.svg" className="w-[220px]" />
                        <img src="/images/daytime-operations.png" className="mt-10" />
                        <img src="/images/distributed-locations.png" className="mt-10" />
                    </div>
                </div>
                <div className="w-full xl:w-[60%] min-h-[100vh] h-full px-4 xl:px-20  py-10 bg-[#92D050] flex flex-col justify-center items-center">
                    <div className="w-full">
                    <div className="absolute right-4 top-4 text-white">2 of 3</div>
                        <div className="font-bold mb-6">
                            <Header
                                className="mb-8 text-white"
                                text="LET'S GET YOU SAVING"
                            />
                        </div>
                        <div className="text-white text-lg font-bold">
                            <p className="mb-4">What are your opening hours?</p>
                            <div className="flex flex-col xl:flex-row mt-8 mb-4 xl:items-center gap-8">
                                <div className="flex gap-2 items-center ">
                                    <p className="w-[100px]">I open at:</p>
                                    <HourSelector selected={selected.open} setSelected={(value) => setSelected({ ...selected, open: value })} />
                                </div>
                                <div className="flex gap-2 items-center ">
                                    <p className="w-[100px]">I close at:</p>
                                    <HourSelector selected={selected.close} setSelected={(value) => setSelected({ ...selected, close: value })} />
                                </div>
                            </div>
                        </div>
                        <div className="text-white text-lg font-bold">
                            <div className="flex mt-8 mb-4">
                                <p className="mt-1.5">How many businesss locations are the assets installed at?</p>
                            </div>
                            <div className="flex grid-flow-row grid-cols-3 xl:space-x-4 space-x-0">
                                <LocationSelection
                                    selected={selected.locations}
                                    onSelect={(value) => setSelected({ ...selected, locations: value })}
                                />
                            </div>
                        </div>
                        <div className="text-white text-lg font-bold">
                            <div className="flex mt-8 mb-4">
                                <p className="mt-1.5">Do all sites have WiFi Internet connectivity?</p>
                            </div>
                            <div className="flex grid-flow-row grid-cols-3 xl:space-x-4 space-x-0">
                                <YesNoSelection
                                    selected={selected.wifi}
                                    onSelect={(value) => setSelected({ ...selected, wifi: value })}
                                />
                            </div>
                        </div>
                        <GradientButton
                            className="mt-16 float-right w-[120px] xl:w-[200px]"
                            onClick={() => {
                                if (validate()) onNext && onNext();
                            }}
                            text="Next"
                        />
                        <GradientButton
                            className="mt-16 w-[120px] xl:w-[200px]"
                            onClick={onBack}
                            text="Back"
                        />
                    </div>
                </div>
            </div>

        </>
    );
};

const Page3: FC<PageProps> = (props: PageProps) => {
    const { onBack, onNext, selected, setSelected, setToken, token, debug } = props;
    const [errors, setErrors] = useState<any>({});

    if (!selected || !setSelected || !setToken) {
        return <></>
    }

    const validate = () => {
        if (debug) return true;

        let ret = true;
        let errorRes = {};
        if (!selected.solution) {
            errorRes = { ...errorRes, solution: { message: "Please select a solution" } };
            ret = false;
        }
        if (!selected.email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(selected.email)) {
            errorRes = { ...errorRes, email: { message: "Please enter a valid email address" } };
            ret = false;
        }
        if (!token) {
            errorRes = { ...errorRes, token: { message: "Please verify the Recaptcha" } };
            ret = false;
        }
        setErrors(errorRes);
        return ret;
    }

    const onVerifyCaptcha = (token: string) => {
        setToken(token);
    };

    return (
        <>
            <div className="flex w-full relative">
                <div className="hidden xl:flex w-[40%] px-10 py-10 flex-col justify-center items-center">
                    <div className="w-full flex flex-col items-center">
                        <img src="/images/raasify.svg" className="w-[220px]" />
                        <img src="/images/saving-left.png" className="mt-10" />
                    </div>
                </div>
                <div className="w-full xl:w-[60%] min-h-[100vh] h-full px-4 xl:px-20  py-10 bg-[#92D050] flex flex-col justify-center items-center">
                    <div className="w-full">
                    <div className="absolute right-4 top-4 text-white">3 of 3</div>
                        <div className="font-bold mb-6">
                            <Header
                                className="mb-8 text-white"
                                text="LET'S GET YOU SAVING"
                            />
                        </div>
                        <div className="text-white text-lg font-bold mb-12">
                            <div className="text-white text-lg font-bold">
                                <div className="flex mt-8 mb-2">
                                    <p className="mb-4">Which Raasify Energy saving solution are you considering?</p>
                                </div>
                                <div className="flex grid-flow-row grid-cols-3 space-x-4">
                                    <SolutionSelection
                                        selected={selected.solution}
                                        onSelect={(value) => setSelected({ ...selected, solution: value })}
                                    />
                                </div>
                            </div>
                            <ErrorMessage message={errors.solution?.message} />
                        </div>
                        <div className="text-white text-lg font-bold">
                            <p className="mt-8 mb-4">Please enter your email address so we can contact you?</p>
                            <input
                                value={selected.email}
                                onChange={(e) => setSelected({ ...selected, email: e.target.value })}
                                type="email"
                                className="border-2 border-[#92D050] w-full h-10 rounded-md px-2 text-black" />
                            <ErrorMessage message={errors.email?.message} />
                        </div>
                        <div className="text-white text-lg font-bold mt-16">
                            <ReCAPTCHA className="mt-4" sitekey={REACT_APP_SITE_KEY} onChange={onVerifyCaptcha as any} />
                            <ErrorMessage message={errors.token?.message} />
                        </div>
                        <GradientButton
                            className="mt-16 float-right w-[120px] xl:w-[200px]"
                            onClick={() => {
                                if (validate()) onNext && onNext();
                            }}
                            text="Done"
                        />
                        <GradientButton
                            className="mt-16  w-[120px] xl:w-[200px]"
                            onClick={onBack}
                            text="Back"
                        />
                    </div>
                </div>
            </div>

        </>
    );
};

const getAssets = (selected: SelectedIF) => {
    let assets = '';
    if (selected.fridge) {
        assets = assets.concat("Refrigeration systems");
    }
    if (selected.vending) {
        if (assets.length > 0) assets = assets.concat(", ");
        assets = assets.concat("Vending machines");
    }
    if (selected.other) {
        if (assets.length > 0) assets = assets.concat(", ");
        assets = assets.concat("Other asset types");
    }
    return assets;
}

const parseBool = (value: boolean | undefined) => {
    if (value === undefined) return "Not specified";
    return value ? "Yes" : "No";
}

const getLocations = (selected: SelectedIF) => {
    if (selected.locations === 1) return "One";
    if (selected.locations === 2) return "Two";
    return "3 +";
}

const Page4: FC<PageProps> = (props: PageProps) => {
    const { onBack, onNext, selected, setSelected, debug } = props;

    if (!selected || !setSelected) {
        return <></>
    }

    return (
        <>
            <div className="flex w-full relative">
                <div className="hidden w-[40%] xl:px-10 py-10 xl:flex flex-col justify-center items-center">
                    <div className="w-full flex flex-col items-center">
                        <img src="/images/raasify.svg" className="w-[220px]" />
                        <p className="mt-12 text-[#92D050] text-4xl font-bold">Thank you!</p>
                        <p className="mt-12 text-[#92D050] text-lg font-bold">We will contact you shortly via {selected.email}.</p>
                    </div>
                </div>
                <div className="w-full xl:w-[60%] min-h-[100vh] h-full px-4 xl:px-20 py-10 bg-[#92D050] flex flex-col justify-center items-center">
                    <div className="w-full relative">
                        <div className="font-bold mb-6">
                            <Header
                                className="mb-8 text-white"
                                text="LET'S GET YOU SAVING"
                            />
                        </div>
                        <div className="mt-10 mb-6 border-[0.1rem]" />
                        <div className="grid grid-cols-2">
                            <p className="text-white font-bold">Business Name:</p>
                            <p className="text-white font-bold">Locations:</p>
                            <p>{selected.name}</p>
                            <p className="mb-4">{getLocations(selected)}</p>
                            <p className="text-white font-bold">Business Address:</p>
                            <p></p>
                            <p className="mb-4">{selected.address}</p>
                            <p></p>
                            <p className="text-white font-bold">Business Assets:</p>
                            <p></p>
                            <p className="mb-4">{getAssets(selected)}</p>
                            <p></p>
                            <p className="text-white font-bold">Business Hours:</p>
                            <p className="text-white font-bold">WiFi Availability:</p>
                            <p className="mb-4">{selected.open} - {selected.close}</p>
                            <p>{parseBool(selected.wifi)}</p>
                            <p className="text-white font-bold">Raasify Energy Solution:</p>
                            <p></p>
                            <div className="rounded-lg bg-white w-[200px] p-4 my-2 h-10 font-bold text-[#92D050] flex justify-center items-center">Energy {selected.solution}</div>
                        </div>

                        <div className="mt-10 border-[0.1rem]" />
                        <GradientButton
                            className="mt-16 float-right w-[260px] xl:w-[300px]"
                            onClick={() => {
                                onNext && onNext();
                            }}
                            text="Next: Saving calculator"
                        />
                        <GradientButton
                            className="mt-16  w-[120px] xl:w-[200px]"
                            onClick={onBack}
                            text="Back"
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export const Start: FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const debug = searchParams.get("debug") ? true : false;

    const [alertOpen, setAlertOpen] = React.useState(false);
    const [confirmOpen, setConfirmOpen] = React.useState(false);
    const [confirmMessage, setConfirmMessage] = useState<string | undefined>();
    const [done, setDone] = React.useState(false);
    const [alertMessage, setAlertMessage] = useState<string | undefined>();
    const [page, setPage] = useState(1);
    const [selected, setSelected] = useState({ fridge: false, vending: false, other: false, open: "09:00", close: "17:00", locations: 1, wifi: true, solution: "Saver" } as SelectedIF);
    const [token, setToken] = useState<string | undefined>();

    const navigate = useNavigate();

    const onCancel = () => {
        setConfirmMessage("Are you sure you want to abort?");
        setConfirmOpen(true);
    }

    const onNext = () => {
        setPage(page + 1);
    }

    const onBack = () => {
        setPage(page - 1);
    }

    const onSubmit = async () => {
        const { email, name, address, open, close, wifi, solution } = selected;

        if (!debug) {

            const message = `
Business Name: ${name}
Business Address: ${address}
Business Assets: ${getAssets(selected)}
Business Hours: ${open} - ${close}
Locations: ${getLocations(selected)}
WiFi Availability: ${parseBool(wifi)}
Raasify Energy Solution: Energy ${solution}
`;

            const response = await fetch('/api/v1/start', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, name, message, token }),
            });
            console.log({ message, response });

            if (response.status !== 200) {
                toast.error("Error submitting form. Please try again.")
            }
            else {
                setPage(page + 1);
            }
        }
        else {
            setPage(page + 1);
        }
    }

    const handleSetAlertOpen = (open: boolean) => {
        setAlertOpen(open);
        if (done && !open) navigate("/");
    }

    const handleSetConfirmSuccess = (success: boolean) => {
        setConfirmOpen(false);
        if (success) navigate("/");
    }

    const onReturn = () => {
        navigate("/");
    }

    const onEstimate = () => {
        navigate("/estimate");
    }

    return (
        <>
            <ConfirmDialog confirmMessage={confirmMessage} open={confirmOpen} setSuccess={handleSetConfirmSuccess} />
            {page === 1 &&
                <Page1
                    debug={debug}
                    onCancel={onCancel}
                    onNext={onNext}
                    selected={selected}
                    setSelected={setSelected}
                />
            }
            {page === 2 &&
                <Page2
                    debug={debug}
                    onBack={onBack}
                    onNext={onNext}
                    selected={selected}
                    setSelected={setSelected}
                />
            }
            {page === 3 &&
                <Page3
                    debug={debug}
                    onBack={onBack}
                    onNext={onSubmit}
                    selected={selected}
                    setSelected={setSelected}
                    token={token}
                    setToken={setToken}
                />
            }
            {page === 4 &&
                <Page4
                    debug={debug}
                    onBack={onReturn}
                    onNext={onEstimate}
                    selected={selected}
                    setSelected={setSelected}
                />
            }
        </>
    )
};
