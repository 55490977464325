import { FC } from "react";
import { Header } from "./Header";


export const SaveThousands: FC = () => {
    return (
        <div className="flex flex-col justify-center">
            <div className="py-4 bg-[#f2f2f2] text-[2rem] text-center font-bold text-[#92D050] xl:hidden w-full">
                <p>raasify.energy</p>
            </div>
            <div className="flex w-full items-center">
                <div className="text-center w-full xl:w-[40%] px-4 pt-1 pb-14 xl:px-10 xl:pt-10">
                    <div className="font-bold text-[#92D050] mb-10 mt-10 xl:mt-0">
                        <Header className="mb-2 text-5xl " text="Save $1000's on" />
                        <Header className="mb-2 text-5xl " text="your business" />
                        <Header className="mb-2 text-5xl " text="energy bills" />
                    </div>
                    <div className="text-2xl text-[#92D050]">
                        <p>A sustainability initiative for</p>
                        <p>commercial fridges and</p>
                        <p>vending machines</p>
                    </div>
                </div>
                <div className="w-[60%] hidden xl:inline">
                    <img src="/images/light-bulb.jpg" alt="fridge" width="100%" />
                </div>
            </div>
            <div className="xl:hidden">
                    <img src="/images/light-bulb.jpg" alt="fridge" width="100%" />
                </div>
        </div>

    );
}