import { FC } from "react";
import { StandardButton, LinkButton } from "./Buttons";
import { scrollToBottom } from "../util/util";
import { Link } from "react-router-dom";

export const TopBar: FC = () => {
    return (
        <div className="bg-[#92D050] min-h-[70px] flex flex-row items-center pl-8 relative">
            <Link to="/">
                <div className="flex flex-row"> 
                    <img src="/cloud.png" alt="logo" className="h-8" />
                    <div className="ml-4 text-[1.5rem] font-bold text-white hidden xl:inline">
                        <p>raasify.energy</p>
                    </div>
                </div>
            </Link>
            <div className="absolute right-[20px]">
                <LinkButton
                    className="xl:mr-10 px-1"
                    text="Pricing"
                    to="/pricing"
                />
                <LinkButton
                    className="xl:mr-10"
                    text="FAQs"
                    to="/faqs"
                />
                <StandardButton
                    onClick={scrollToBottom}
                    className="w-[55px] xl:w-[150px]"
                >
                    <div className="flex justify-center">
                        <div className="flex items-center">
                            <img src="/email.jpg" alt="logo" className="h-[14px] mr-3" />
                            <p className="text-sm hidden xl:inline">Contact us</p>
                        </div>
                    </div>
                </StandardButton>
            </div>


        </div>
    );
}