import { FC } from "react";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";

interface HourSelectorProps {
    selected?: string;
    setSelected?: (selected: string) => void;
}

export const HourSelector: FC<HourSelectorProps> = (props: HourSelectorProps) => {
    const { selected, setSelected } = props;
    return (
        <Select
            onChange={(e) => setSelected && setSelected(e.target.value)}
            value={selected}
            className="border-2 border-[#92D050] w-48 h-10 rounded-md px-2 text-black bg-white"
        >
            <MenuItem value="00:00">12:00 AM</MenuItem>
            <MenuItem value="00:30">12:30 AM</MenuItem>
            <MenuItem value="01:00">01:00 AM</MenuItem>
            <MenuItem value="01:30">01:30 AM</MenuItem>
            <MenuItem value="02:00">02:00 AM</MenuItem>
            <MenuItem value="02:30">02:30 AM</MenuItem>
            <MenuItem value="03:00">03:00 AM</MenuItem>
            <MenuItem value="03:30">03:30 AM</MenuItem>
            <MenuItem value="04:00">04:00 AM</MenuItem>
            <MenuItem value="04:30">04:30 AM</MenuItem>
            <MenuItem value="05:00">05:00 AM</MenuItem>
            <MenuItem value="05:30">05:30 AM</MenuItem>
            <MenuItem value="06:00">06:00 AM</MenuItem>
            <MenuItem value="06:30">06:30 AM</MenuItem>
            <MenuItem value="07:00">07:00 AM</MenuItem>
            <MenuItem value="07:30">07:30 AM</MenuItem>
            <MenuItem value="08:00">08:00 AM</MenuItem>
            <MenuItem value="08:30">08:30 AM</MenuItem>
            <MenuItem value="09:00">09:00 AM</MenuItem>
            <MenuItem value="09:30">09:30 AM</MenuItem>
            <MenuItem value="10:00">10:00 AM</MenuItem>
            <MenuItem value="10:30">10:30 AM</MenuItem>
            <MenuItem value="11:00">11:00 AM</MenuItem>
            <MenuItem value="11:30">11:30 AM</MenuItem>
            <MenuItem value="12:00">12:00 PM</MenuItem>
            <MenuItem value="12:30">12:30 PM</MenuItem>
            <MenuItem value="13:00">01:00 PM</MenuItem>
            <MenuItem value="13:30">01:30 PM</MenuItem>
            <MenuItem value="14:00">02:00 PM</MenuItem>
            <MenuItem value="14:30">02:30 PM</MenuItem>
            <MenuItem value="15:00">03:00 PM</MenuItem>
            <MenuItem value="15:30">03:30 PM</MenuItem>
            <MenuItem value="16:00">04:00 PM</MenuItem>
            <MenuItem value="16:30">04:30 PM</MenuItem>
            <MenuItem value="17:00">05:00 PM</MenuItem>
            <MenuItem value="17:30">05:30 PM</MenuItem>
            <MenuItem value="18:00">06:00 PM</MenuItem>
            <MenuItem value="18:30">06:30 PM</MenuItem>
            <MenuItem value="19:00">07:00 PM</MenuItem>
            <MenuItem value="19:30">07:30 PM</MenuItem>
            <MenuItem value="20:00">08:00 PM</MenuItem>
            <MenuItem value="20:30">08:30 PM</MenuItem>
            <MenuItem value="21:00">09:00 PM</MenuItem>
            <MenuItem value="21:30">09:30 PM</MenuItem>
            <MenuItem value="22:00">10:00 PM</MenuItem>
            <MenuItem value="22:30">10:30 PM</MenuItem>
            <MenuItem value="23:00">11:00 PM</MenuItem>
            <MenuItem value="23:30">11:30 PM</MenuItem>
        </Select>
    )
}