import { Link } from "react-router-dom";
import { cn } from "../util/util";

interface ButtonParams {
    onClick?: () => void;
    className?: string;
    text?: string;
    children?: any;
    to?: string;
    disabled?: boolean;
}

export const StandardButton = (props: ButtonParams) => {
    const { className, text, onClick, children } = props;
    return (
        <button onClick={onClick} className={cn("hover:bg-gray-300 active:bg-gray-500 text-lg mt-0 py-0 bg-white text-black px-4  w-[150px] h-[40px] rounded-full", className || "")}>
            {children}
            {text}
        </button>
    );
}

export const GradientButton = (props: ButtonParams) => {
    const { className, text, onClick, disabled } = props;
    const classes = !disabled 
        ? cn("hover:from-emerald-700 hover:to-indigo-700 active:from-emerald-900 active:to-indigo-900 bg-gradient-to-r from-indigo-500",
            "from-10% via-sky-500 via-30% to-emerald-500 to-90% text-white font-bold text-xl rounded-full h-[50px]", className || "")
            : cn("bg-gray-500 text-white font-bold text-xl rounded-full h-[50px]", className || "");
    return (
        <button 
            disabled={disabled}
            onClick={() => {
                if(!disabled && onClick) {
                    onClick();
                }
            }} 
            className={classes}
        >
            {text}
        </button>
    );
}


interface LinkButtonParams {
    className?: string;
    text: string;
    to: string;
}

export const LinkButton = (props: LinkButtonParams) => {
    const { className, text, to } = props;
    return (
        <Link 
            to={to}
            className={cn("hover:text-gray-200 active:text-gray-500 text-lg  mt-0 py-0 bg-none text-white px-4 h-[40px]", className || "")}
        >
            {text}
        </Link>
    );
}


export const GradientLinkButton = (props: LinkButtonParams) => {
    const { className, text, to } = props;
    return (
        <Link 
            to={to}
            className={cn("text-center hover:from-emerald-700 hover:to-indigo-700 active:from-emerald-900 active:to-indigo-900 bg-gradient-to-r from-indigo-500",
            "from-10% via-sky-500 via-30% to-emerald-500 to-90% text-white font-bold text-xl rounded-full w-[200px] h-[50px]", className || "")}
        >
            <div className="flex items-center justify-center h-full">{text}</div>
        </Link>
    );
}
