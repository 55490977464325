import { FC } from "react";
import { GradientButton } from "./Buttons";
import { Header } from "./Header";
import { scrollToFAQ } from "./FAQ";

export const HowDoesItWork: FC = () => {
    return (
        <div className="w-full relative px-12 xl:px-20 py-16 xl:py-24 bg-[#F2F2F2]">
            <Header
                className="text-center"
                text="How does it all work?"
            />
            <div className="flex-row justify-center xl:flex text-center xl:text-left">
                <div className="text-xl pt-12 xl:pt-20 xl:w-[25%]">
                    <p className="text-[#92D050] font-bold">TEMPERATURE</p>
                    <p>In-fridge environmental measurements.</p>
                    <p className="text-[#92D050] font-bold pt-8 xl:pt-20">HUMIDITY</p>
                    <p>Measurement of axillary system operations.</p>
                    <p className="text-[#92D050] font-bold pt-8 xl:pt-20">MOTION</p>
                    <p>Asset movement or door opening detection.</p>
                </div>
                <img src="/images/tablet-fridge.jpg" alt="store" className="hidden xl:inline  w-[600px] pt-20 px-10" />
                <div className="text-xl pt-8 xl:pt-14 xl:w-[25%]">
                    <p className="text-[#92D050] font-bold">REAL TIME HEALTH</p>
                    <p>Web reporting and management application.</p>
                    <p className="text-[#92D050] font-bold pt-8 xl:pt-20">POWER MONITOR</p>
                    <p>Power consumption control.</p>
                    <GradientButton
                        onClick={scrollToFAQ}
                        className="w-48 h-[50px] mt-16 xl:mt-32"
                        text="See FAQs"
                    />
                </div> 
            </div>      
        </div>
    );
}