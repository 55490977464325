export const FAQ_QUESTIONS = [
    {
        question: "How can Raasify Energy help my business?",
        answer: "The Raasify energy solution aims to leverage advanced technology to reduce your business operational costs in an environmentally responsible way. The Raasify energy solution for refrigeration systems and vending machines, helps businesses reduce their monthly electricity bills, increase the efficiency and longevity of integral and remote refrigeration systems and vending machine assets, while minimizing the potential for ongoing expensive technical repair works. Specifically, the Raasify Energy can help your business including service providers and manufacturers in the following manner:",
        points: [
            {tag: "Transform any refrigeration system and vending machines into smart assets", text: "Continuously and in real time track data from assets for automated data analysis to identify components degradation before they become expensive repairs."},
            {tag: "Remote control of your asset", text: "No longer assume that your refrigeration systems and vending machines are set to the right temperature, or your product is being appropriately cooled. The remote-control portal allows you to monitor and control your asset's power consumption and thermostat setting right from your computer to ensure of continuous operations - just like you set it."},
            {tag: "Eliminate unnecessary energy costs", text: "As the costs of energy increase, take control of your energy bills. Leaving assets unnecessarily powered may lead to expensive electricity bills. The Raasify energy empowers businesses to monitor and minimize assets energy consumption over time."},
            {tag: "Reduced product spoilage", text: "Real-time monitoring and alerting of assets' temperature and humidity, inform owners if assets exceed their operational safe range thereby significantly reducing the risk of food spoilage."},
            {tag: "Proactive failure detection", text: "During correct operations, the energy consumed by commercial refrigeration equipment and vending machines follows a set pattern. Deviation from this pattern, may indicate inefficient operations or pending component failure."},
            {tag: "Optimize supply chain", text: "Often, servicing technicians must inspect refrigeration assets and vending machines to determine which component has failed. Once identified, components need to be ordered and finally, upon availability, installed in a second asset visit. By remotely monitoring asset operations, service providers can optimize supply chain, minimize site visits and reduce asset downtime. Asset operators can schedule replacements of the failing components to coincide with off-peak periods, providing less disruption to business operations."},
            {tag: "Adherence to regulatory compliance", text: "With continuous reporting of your asset's power consumption, temperature, humidity and movement, the Raasify Energy solution provides critical reports to prove product operations and integrity, which may be used to prove adherence to regulatory compliance."},
            {tag: "Brand trust and identity", text: "The Raasify Energy solution provides you with the required information to classify which asset brands and models in your business are more reliable and efficient, empowering you to make the right decisions when considering expansions or asset replacement investments."}
        ]
    },
    {
        question: "Can you describe the Raasify energy solution components?",
        answer: "The Raasify energy solution comprises of both hardware and software components. Hardware sensors leverages simple to deploy and operate technology to wirelessly monitor and optimize the power consumption and ongoing operations of refrigeration systems and vending machines. No special rewiring or change to existing equipment is required. Businesses can see in real time the status and health of each fridge or vending machine, while the technology continuously works in the background to save them money."
    },
    {
        question: "What is different about the Raasify energy solution?",
        answer: "The Raasify energy solution is a game changer in power and asset management. Using advanced technology, Raasify energy makes capabilities that are usually exclusively available to large businesses, accessible to every business regardless of size or location at a fraction of the cost. The solution eliminates high upfront costs, complicated installation procedures and complex ongoing operations, to deliver maximum savings for businesses. Don't believe it - contact us to try Raasify energy no-obligation risk-free. If your business doesn't see immediate savings, you are free to cancel anytime."
    },
    {
        question: "What are Raasify energy solution monitors?",
        answer: "Raasify energy solution monitors are non-intrusive hardware sensors that gather information from assets they monitor and report to the Raasify solution for analysis. Raasify energy solution monitors can monitor power consumption, temperature, humidity and movement."
    },
    {
        question: "I have a mix of refrigeration systems and vending machines from different manufacturers. Is that supported?",
        answer: "Yes. The solution can monitor and optimize commercial refrigeration or vending machines regardless of equipment manufacturer. From standalone display fridges to storage cabinets and bottle dispensing vending machines - and more, the Raasify energy solution can monitor, optimize and help you save on your electricity bills."
    },
    {
        question: "Can I trial the Raasify energy solution before deciding?",
        answer: "Yes. Please contact us to discuss to schedule your no-obligation risk free trial."
    },
    {
        question: "Does the Raasify Energy solution affect the warrantee of my fridge or vending machine?",
        answer: "No. The Raasify energy solution does not require any changes to your commercial refrigeration or vending machine designs, installation procedures or technical support and repair practices."
    },
    {
        question: "How difficult is it to retrofit the Raasify energy solution to new or older model fridges or vending machines?",
        answer: "Commercial refrigeration systems and vending machines can be outfitted with Raasify’s simple to deploy wireless monitors in under 60 seconds. No special rewiring or changes to existing equipment is required."
    },
    {
        question: "I have solar panels installed. Does that affect the Raasify energy solution?",
        answer: "Solar panels assist in renewable energy generation. In contract, the Raasify energy solution for refrigeration systems and vending machines works to optimize energy consumption. While its also true that solar panels reduce the reliance on grid-delivered energy during daytime operations, and hence reduce the cost of energy consumption, the Raasify energy solution complements this approach, optimizing total power consumption, whether daytime or nighttime."
    },
    {
        question: "My commercial fridge or vending machine is already energy efficient. Do I still need the Raasify energy solution?",
        answer: "Yes. The Raasify energy solution for refrigeration systems and vending machines continuously optimizes equipment operations reacting to environment factors in real time. Such capabilities combine with your asset’s energy efficiency capabilities to maximize savings, minimize expensive repairs and reduce environmental impact for businesses over time."
    },
    {
        question: "My business doesn’t pay the electricity bills. Can the Raasify energy solution still help to my business?",
        answer: "Electricity cost is just one dimension of power consumption. Environmental impacts such as the usage of dirty (non-renewable) energy and CO2 emissions are also important. Minimizing these, can help a business meet its sustainability objectives and contribute to local and global initiatives to protect our planet. Beyond energy management, the Raasify energy solution helps businesses proactively maintain/service their fridge and vending machine assets, minimizing costs that are often incurred with non-managed and unmaintained assets."
    },
    {
        question: "How does Raasify help my business reduce its electricity costs?",
        answer: "Using advanced predictive software algorithms, the Raasify energy solution makes real time decisions to optimize power usage for refrigeration systems and vending machines. Depending on desired outcomes, the solution may: completely turn-off equipment (for example when the business is closed) or vary compressor behavior based on existing or predictive environmental conditions, to invoke power efficient operations. These changes help reduce power consumption over time and translate to real financial savings for the business."
    },
    {
        question: "Doesn't turning off fridges or vending machines cause damage to the equipment?",
        answer: "No. The Raasify solution incorporates measures to prevent any potential damage to equipment. To protect equipment from power related hazards, the solution employs power monitors that offer shortage and overload protection. Furthermore, the  Raasify’s energy management software intelligently monitors power consumption variables, equipment state, compressor status and compressor running intervals to determine when to change fridge/vending machine operations. Minimum power-off and power-on intervals ensure that compressors cycles are not abruptly interrupted or toggled on and off indiscriminately. This approach does not only prevent damage to equipment, but rather prolong its operational lifetime (for example preventing unnecessary 24/7 asset operations that eventually lead to component wear and tear) while minimizing equipment failure."
    },
    {
        question: "Is the Raasify energy solution suitable for fridges or vending machines filled with perishable items?",
        answer: "Yes. The Raasify energy solution offers many benefits to businesses (refer: How can Raasify Energy help my business) operating fridge and vending machine assets, many of which are independent of type of items filling such assets. For assets housing perishable items that must be kept at a constant temperature, the Raasify energy solution offers enhanced monitoring and reporting capabilities that may be used to optimize operations and prolong asset lifetime."
    },
    {
        question: "How does the Raasify energy solution cater for differing stored items?",
        answer: "The Raasify energy solution leverages power optimization algorithms to optimize environmental conditions to suite differing stored item requirements. Depending on the desired environmental conditions, the Raasify energy power optimization algorithms may be set to be either time-dependent or temperature dependent. In time-dependent mode, power consumption is optimized without considering temperature, letting in-fridge temperatures reach ambient temperature levels. For items housed in refrigeration systems or vending machines that are more sensitive to temperature variations, temperature-dependent mode may be utilized. In this mode, power consumption is optimized considering the stored items’ temperature constraints."
    },
    {
        question: "Do I need electricians or special trades to install or operate the Raasify energy solution?",
        answer: "No. Standalone, integral or remote commercial refrigeration systems and vending machines that are plugged into standard power sockets can be outfitted with Raasify’s simple to deploy wireless monitors in under 60 seconds. No special rewiring or changes to existing equipment is required. For hard-wired assets, please contact us to discuss installation."
    },
    {
        question: "Does my business location require to have Internet access?",
        answer: "Yes. The initial focus for the Raasify solution relies on customer provided reliable WiFi-based Internet access. If your business location does not have Internet access, please reach out to discuss options."
    },
    {
        question: "How hard is it to remove the Raasify energy solution if I decide not to continue?",
        answer: "Really simple. Hardware may be simply removed or kept in place - as these do not interfere with the equipment during its operation. Furthermore, businesses have an option of not renewing their subscription to the Raasify energy solution, which would revert refrigeration systems and vending machines back to the standard (pre-Raasify) mode of operation."
    },
    {
        question: "Do you support any other electricity powered asset type?",
        answer: "Yes. Please contact us to discuss your asset type requirements."
    },
    {
        question: "Does my fridge or vending machine continue to operate if the Raasify energy solution stops working?",
        answer: "Yes. Should any components of the Raasify energy solution fail, your refrigeration system or vending machine will revert to its standard (pre-Raasify) mode of operation."
    },
    {
        question: "Does the Raasify energy solution assist in protecting equipment from power surges?",
        answer: "The Raasify solution does not only interoperate with existing power surge protection mechanisms used by businesses for refrigeration systems and vending machines, but further enhances such capabilities by implementing hardware (smart power monitors) that are equipped with shortage and overload protection circuitry."
    },
    {
        question: "What is the Raasify energy pricing model?",
        answer: "The Raasify energy pricing model is designed to be simple and predictable, allowing businesses to understand and predict costs vs generated business savings. The model consists of multiple Raasify energy plans  a single upfront cost (hardware monitors and gateways) and a subscription-based service fee. Refer to our pricing page for more details."
    },
    {
        question: "Are there minimum contract or subscription terms that my business must commit to, or can I terminate at any time?",
        answer: "No. Businesses may subscribe to monthly payments without term-based commitments."
    },
    {
        question: "How quickly will my business realize electricity savings?",
        answer: "Once the Raasify energy solution is implemented, businesses will start seeing electricity savings resulting from reduction in power consumption immediately. No more waiting for months to realize savings. Saving amounts will vary depending on period and power optimization strategy implemented by the business."
    }
];