import { FC } from "react";
import { cn } from "../util/util";

export interface HeaderParams {
    className?: string;
    text: string;
}

export const Header: FC<HeaderParams> = (props: HeaderParams) => {
    const { text, className } = props;
    return (
        <p className={cn("text-4xl font-bold text-[#92D050]", className || "")}>
            {text}
        </p>
    );
}