import { FC } from "react";
import { Header } from "./Header";

export interface HowCanWeHelpColumnParams {
    imageLocation: string;
    header: string;
    text: string;
}

export const HowCanWeHelpColumn: FC<HowCanWeHelpColumnParams> = (props) => {
    const { imageLocation, header, text } = props;
    return (
        <div className="px-16 pt-10 xl:pt-16 xl:w-1/3 flex flex-col">
            <img src={imageLocation} alt="fridge" className="w-24 xl:w-fit xl:h-32 self-center xl:self-auto" />
            <p className="mt-6 text-3xl font-bold xl:text-left text-[#92D050]">{header}</p>
            <p className="mt-6 text-xl xl:text-left text-black">{text}</p>
        </div>
    )
}

export const HowCanWeHelp: FC = () => {
    return (
        <div className="w-full py-24 text-center px-4 xl:px-24 bg-[#F2F2F2] flex flex-col">
            <Header
                text="How can Raasify Energy help your business?"
                className="mb-10 xl:mb-0"
            />
            <div className="flex flex-col xl:flex-row w-full">
                <HowCanWeHelpColumn
                    imageLocation="/images/timer.png"
                    header="Monitor"
                    text="Monitor power consumption and environmental conditions."
                />
                <HowCanWeHelpColumn
                    imageLocation="/images/bar-chart.png"
                    header="Optimize"
                    text="Continuously optimize equipment settings to optimize operations."
                />
                <HowCanWeHelpColumn
                    imageLocation="/images/cash.png"
                    header="Save"
                    text="Realize immediate savings & increase equipment longevity."
                />
            </div>
        </div>
    );
}