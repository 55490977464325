import { useEffect, useState } from "react";
import ReCAPTCHA from 'react-google-recaptcha'
import { toast } from "react-toastify";
import { useForm, useFormState } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Header } from "./Header";
import { cn } from "../util/util";
import { REACT_APP_SITE_KEY } from "../util/settings";

interface EmailFormParams {
    email: string;
    name: string;
    message: string;
    token: string;
}

const validationSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('Email is invalid'),
    name: Yup.string().required('Name is required'),
    message: Yup.string().required('Message is required'),
    token: Yup.string().required('Please confirm you are not a robot'),
});
const formOptions = { resolver: yupResolver(validationSchema) };

export const ContactUsForm = (props: any) => {
    const form = useForm<EmailFormParams>(formOptions as any);
    const { control, handleSubmit, register, formState, reset, setValue } = form;
    const { isDirty } = useFormState({ control });
    const { errors } = formState;

    const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);

    useEffect(() => {
        reset({
            email: '',
            name: '',
            message: '',
            token: '',
        })
    }, [isSubmitSuccessful])

    const submitFormToAPI = async (params: any) => {
        console.log({ params });
        const { email, name, message, token } = params;
        const payload = { email, name, message, token };
        const response = await fetch('/api/v1/contactus', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, name, message, token }),
        });
        console.log({ payload, response });

        if (response.status !== 200) {
            toast.error("Error submitting form. Please try again.")
        }
        else {
            toast("Thank you! We will get back to you shortly.")
            setIsSubmitSuccessful(true);
        }
    }

    const onVerifyCaptcha = (token: string) => {
        setValue('token', token);
    };

    const inputClassName = "appearance-none block w-full bg-white text-black border border-gray-300 rounded py-5 px-8 mb-3 leading-tight focus:outline-none focus:bg-white";
    const orangeClassName = "text-orange-500";
    const blackClassName = "text-black";

    return (
        <div className="flex flex-col xl:flex-row items-center pt-20 xl:pt-0">
            <div className="text-4xl font-bold xl:w-[50%] px-10 xl:px-20 text-[#343f5a] text-center xl:text-left">
                <p className="mb-6">Reach Out To <span className={orangeClassName}>Ask Questions</span></p>
                <p><span className={blackClassName}>or</span> sign up to <span className={orangeClassName}>Start Saving</span></p>
            </div>
            <div className="mx-14 my-10 xl:w-[50%] p-6 text-center xl:text-left">
                <Header text="Get in touch" />
                <p className="mt-10 text-xl">Fill in the form below to get in touch with us</p>
                <form className="flex flex-col mt-8" onSubmit={handleSubmit(submitFormToAPI)} noValidate>
                    <div className="flex flex-col xl:flex-row">
                        <div className="flex flex-col w-full xl:mr-4">
                            <input
                                className={cn(inputClassName, "rounded-full")}
                                type={'text'}
                                placeholder="Your name"
                                {...register("name")}
                            />
                            <div className="text-red-500 xl:pl-8 mb-4">{errors.name?.message}</div>
                        </div>
                        <div className="flex flex-col w-full xl:ml-4">
                            <input
                                className={cn(inputClassName, "rounded-full ")}
                                type={'email'}
                                placeholder="Your email"
                                {...register("email")}
                            />
                            <div className="text-red-500 pl-8 mb-4">{errors.email?.message}</div>
                        </div>
                    </div>
                    <textarea
                        className={cn(inputClassName, "h-[200px] mt-4 rounded-3xl")}
                        placeholder="Message"
                        {...register("message")}
                    />
                    <div className="text-red-500 pl-8 mb-4">{errors.message?.message}</div>
                    <ReCAPTCHA className="mt-4" sitekey={REACT_APP_SITE_KEY} onChange={onVerifyCaptcha as any} />
                    <div className="text-red-500 pt-4 mb-4">{errors.token?.message}</div>
                    <div className="mt-2">
                        <button type="submit"
                            className={cn("hover:from-emerald-700 hover:to-indigo-700 active:from-emerald-900 active:to-indigo-900 bg-gradient-to-r from-indigo-500",
                                "from-10% via-sky-500 via-30% to-emerald-500 to-90% text-white font-bold text-xl rounded-full w-[250px] h-[50px]", "" || "")}>
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>

    )
}
