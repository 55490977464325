import { FC } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

interface ConfirmDialogProps {
    confirmMessage: string | undefined;
    open: boolean;
    setSuccess: (success: boolean) => void;
}

export const ConfirmDialog: FC<ConfirmDialogProps> = (props: ConfirmDialogProps) => {
    const { confirmMessage, open, setSuccess } = props;

    return (
        <Dialog
            open={open}
            onClose={() => setSuccess(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <p className="pt-2 px-4 text-[#92D050]">
                    {"Abandon progress?"}
                </p>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" className="pt-2 px-4">
                    {confirmMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <div className="mb-2 px-2">
                    <Button onClick={() => {
                        setSuccess(false);
                    }}>Cancel</Button>
                </div>
                <div className="mb-2 px-2">
                    <Button autoFocus onClick={() => {
                        setSuccess(true);
                    }}>OK</Button>
                </div>
            </DialogActions>
        </Dialog>
    );
}