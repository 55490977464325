import React, { FC, createRef, useEffect } from "react";
import { Header } from "./Header";
import { FAQ_QUESTIONS } from "./FAQQuestions";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { GradientLinkButton } from "./Buttons";
import { scrollToTop } from "../util/util";

interface FAQParams {
    short?: boolean;
}

interface FAQQuestionParams {
    number: number;
    question: string;
    answer: string;
}

const FAQ = createRef();

export const scrollToFAQ = () => {
    (FAQ.current as any)?.scrollIntoView({ behavior: "smooth" });
}

export const FAQs: FC<FAQParams> = (params: FAQParams) => {
    const { short } = params;
    const [expanded, setExpanded] = React.useState<string | false>(false);

    useEffect(() => {
        if(!short) {
            scrollToTop();
        }
    }, []);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    const questions = short ? FAQ_QUESTIONS.slice(0, 5) : FAQ_QUESTIONS;

    return (
        <div className="py-16 xl:px-32 bg-[#F2F2F2]" ref={FAQ as React.RefObject<HTMLDivElement>}>
            <Header
                className="text-center"
                text="Frequently Asked Questions"
            />
            <p className="text-xl mt-10 mx-12 xl:mx-16 text-center">
                Here are some of the common questions businesses have been asking us. If you have a question not listed below, please contact us and will be more than happy to answer and add it here for the benefit of others
            </p>
            <div className="flex w-full items-center justify-center mt-10">
                <div className="w-full px-0 xl:px-16">
                    {questions.map((item, index) => {
                        const panel = `panel${index + 1}`;
                        return (
                            <Accordion
                                key={`faq_accordian_${index}`} 
                                expanded={expanded === panel}
                                onChange={handleChange(panel)}
                                disableGutters
                                elevation={0}
                                sx={{
                                    '&:before': {
                                        display: 'none',
                                    },
                                    '&.MuiExpansionPanel-root:before': {
                                        display: 'none',
                                    },
                                    border: 'none',
                                    boxShadow: 'none',
                                    marginBottom: '1.2rem',
                                    borderRadius: '30px !important',
                                }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`${panel}bh-content`}
                                    id="${panel}bh-header"
                                    sx={{ backgroundColor: 'white', paddingX: '2rem', paddingY: '0.5rem', borderRadius: '10px', boxShadow: 'none', border: 'none'}}
                                >
                                    <Typography sx={{ color: 'text.primary', fontSize: '1.2rem', fontWeight: '500' }} >{item.question}</Typography>
                                </AccordionSummary>
                                <AccordionDetails
                                    sx={{ backgroundColor: 'white', paddingX: '2rem', paddingBottom: '2rem', borderRadius: '10px', border: 'none', fontSize: '1rem'}}
                                >
                                    <div>
                                        {item.answer}
                                    </div>
                                    {item.points && item.points.length > 0 && (
                                        <ul className="list-disc ml-8 mt-4">
                                            {item.points.map((point, index2) => (
                                                <li key={`faq_${index}_${index2}`} className="mt-4 text-[1rem]">
                                                    <b>{point.tag}</b> - {point.text}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </AccordionDetails>
                            </Accordion>
                        )
                    })}
                </div>
            </div>
            {short && <div className="flex w-full justify-center pt-10 pb-4">
                <GradientLinkButton
                    text="See all FAQs"
                    to="/faqs"
                />
            </div>}
        </div>
    );
}
