import { FC } from "react";
import { Header } from "./Header";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { cn, numberWithCommas, scrollToBottom } from "../util/util";

import React from "react";
import { GradientButton, GradientLinkButton } from "./Buttons";
import Switch from "./Switch";

const thBase = "text-black xl:px-[80px] py-2 pt-8 font-bold";
const tdBase =
    "py-2 border-[1px] border-[#ddd] border-r-[#ddd] border-l-[#ddd] border-t-[#ddd] text-left bg-[#F2F2F2] text-[#92D050] font-bold";
const tdIndented = cn(tdBase, "pl-[20px] xl:pl-[60px] xl:pr-[60px] font-normal");
const tdCentered = cn(tdBase, "pl-0 text-center");


const INITIAL_ASSETS = 1;


const Tick: React.FC = () => {
    return <DoneIcon style={{ color: "#92D050", fontSize: "20px" }} />;
};

const Cross: React.FC = () => {
    return <CloseIcon style={{ color: "#bbb", fontSize: "20px" }} />;
};

interface TitleParams {
    text: string;
}

const Title: React.FC<TitleParams> = (params: TitleParams) => {
    const { text } = params;
    return (
        <tr>
            <td colSpan={4} className={cn(tdBase, "pl-[15px]")}>{text}</td>
        </tr>
    );
};


const Spacer: React.FC = () => {
    return (
        <tr>
            <td colSpan={3} className={cn("border-[0px] h-[45px]")}>&nbsp;</td>
        </tr>
    );
};

interface ItemParams {
    item: string;
    monitor: boolean;
    saver: boolean;
    optimizer: boolean;
}

const Item: React.FC<ItemParams> = (params: ItemParams) => {
    const { item, monitor, saver, optimizer } = params;
    return (
        <tr>
            <td className={tdIndented}>{item}</td>
            <td className={tdCentered}>{monitor ? <Tick /> : <Cross />}</td>
            <td className={tdCentered}>{saver ? <Tick /> : <Cross />}</td>
            <td className={tdCentered}>{optimizer ? <Tick /> : <Cross />}</td>
        </tr>
    );
};

const Comparison: React.FC = () => {
    return (
        <div className="px-0">
            <table className="mb-20">
                <thead>
                    <tr>
                        <th className={thBase}></th>
                        <th className={thBase}>
                                Energy Monitor
                        </th>
                        <th className={thBase}>
                            Energy Saver
                        </th>
                        <th className={thBase}>
                            Energy Optimizer
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <Title text="Reporting & Analytics" />
                    <Item
                        item="Power consumption"
                        monitor={true}
                        saver={true}
                        optimizer={true}
                    />
                    <Item
                        item="In-cabinet Temperature"
                        monitor={false}
                        saver={false}
                        optimizer={true}
                    />
                    <Item
                        item="In-cabinet Humidity"
                        monitor={false}
                        saver={false}
                        optimizer={true}
                    />
                    <Item
                        item="Asset motion detection"
                        monitor={false}
                        saver={false}
                        optimizer={true}
                    />
                    <Spacer />
                    <Title text="Actions" />
                    <Item
                        item="Schedule-based"
                        monitor={false}
                        saver={true}
                        optimizer={true}
                    />
                    <Item
                        item="Temperature-based"
                        monitor={false}
                        saver={false}
                        optimizer={true}
                    />
                    <Item
                        item="Humidity-based"
                        monitor={false}
                        saver={false}
                        optimizer={true}
                    />
                    <Item
                        item="Trigger-based"
                        monitor={false}
                        saver={true}
                        optimizer={true}
                    />

                    <Spacer />
                    <Title text="General" />
                    <Item
                        item="Secure user-logic"
                        monitor={true}
                        saver={true}
                        optimizer={true}
                    />
                    <Item
                        item="Asset grouping"
                        monitor={true}
                        saver={true}
                        optimizer={true}
                    />
                    <Item
                        item="Asset health reporting"
                        monitor={true}
                        saver={true}
                        optimizer={true}
                    />
                    <Item
                        item="External data integration"
                        monitor={true}
                        saver={true}
                        optimizer={true}
                    />

                    <Spacer />
                    <Title text="Alarming & Notifications" />
                    <Item
                        item="Email"
                        monitor={true}
                        saver={true}
                        optimizer={true} />
                    <Item
                        item="IM message"
                        monitor={true}
                        saver={true}
                        optimizer={true} />
                    <Item
                        item="SMS"
                        monitor={true}
                        saver={true}
                        optimizer={true} />

                    <Spacer />
                    <Title text="Connectivity" />
                    <Item
                        item="Gateway"
                        monitor={false}
                        saver={false}
                        optimizer={true}
                    />
                    <Item
                        item="Wired"
                        monitor={false}
                        saver={false}
                        optimizer={true}
                    />
                    <Item
                        item="WiFi"
                        monitor={true}
                        saver={true}
                        optimizer={true}
                    />
                    <Item
                        item="4G/5G"
                        monitor={false}
                        saver={false}
                        optimizer={true}
                    />

                    <Spacer />
                    <Title text="Data Management" />
                    <tr>
                        <td className={tdIndented}>History</td>
                        <td className={tdCentered}>Last 30 days</td>
                        <td className={tdCentered}>Last 30 days</td>
                        <td className={tdCentered}>Last 12 months</td>
                    </tr>
                    <Item item="Backup" monitor={false} saver={false} optimizer={true} />
                </tbody>
            </table>
        </div>

    );
};

// Create a divider component which is a bold gray line
interface DividerParams {
    className?: string;
}

const Divider: React.FC<DividerParams> = (params: DividerParams) => {
    const { className } = params;
    return (
        <div
            className={cn("border-b-2 border-[#eee] w-full", className || "")}
        ></div>
    );
};

interface InputsParams {
    assets: number;
    setAssets: (value: number) => void;
}

const Inputs: FC<InputsParams> = (params: InputsParams) => {
    const { assets, setAssets } = params;
    return (
        <>
            <div className="flex w-full mt-3">
                <p className="py-3 mx-4">Assets</p>
                <div className="flex ml-4">
                    <input
                        type="number"
                        className="border-2 border-[#92D050] w-[235px] p-4 h-12 rounded-[10px]"
                        value={assets}
                        onChange={(e) => {
                            const value = parseInt(e.target.value);
                            if (value > 0) {
                                setAssets(value);
                            }
                        }}
                    />
                </div>
            </div>

            <Divider className="mt-3" />
        </>
    );
};

const MostPopular: FC = () => {
    return (
        <div
            className={cn(
                "bg-[#92D050] text-white font-bold px-4 text-2xl h-[50px] mx-4 xl:mx-2 mb-2 flex items-center justify-center"
            )}
        >
            Most Popular
        </div>
    );
};

const Promo: FC = () => {
    return (
        <div
            className={cn(
                "bg-gradient-to-r from-indigo-500",
                "from-10% via-sky-500 via-30% to-emerald-500 to-90% text-white font-bold py-4 px-4 text-2xl h-[50px] mx-4 xl:mx-2 mb-2 flex items-center justify-center"
            )}
        >
            <span className="text-[#FFC000] mr-2">PROMO:</span>2 months
            <span className="text-[#FFC000] ml-2">FREE</span>
        </div>
    );
};

interface CostsParams {
    licensePerAsset: number;
    assets: number;
    fixed?: number;
}

const Costs: FC<CostsParams> = (params: CostsParams) => {
    const { licensePerAsset, assets, fixed } = params;
    const _fixed = fixed || 0;
    let totalMonthly = licensePerAsset * assets;

    totalMonthly = parseFloat((totalMonthly).toFixed(2));
    const totalYearly = parseFloat((totalMonthly * 12).toFixed(2));

    const licensePerAssetStr = `${licensePerAsset}`;

    return (
        <div className="flex items-center mt-2 text-sm">
            <div className="flex flex-col p-2">
                <p className="py-2 text-xl">
                    <span className="text-3xl font-bold">${licensePerAssetStr}</span>/month/asset*
                </p>
                <p className="py-2">
                    Billed at ${totalYearly}/year
                </p>
            </div>
        </div>
    );
};

interface ProductParams {
    title: string;
    description: string[];
    tagline: string[];
    upfrontPerAsset: number;
    licensePerAsset: number;
    mostPopular?: boolean;
    fixed?: number;
}

const Product: FC<ProductParams> = (params: ProductParams) => {
    const [assets, setAssets] = React.useState<number>(INITIAL_ASSETS);

    const {
        title,
        description,
        tagline,
        upfrontPerAsset,
        licensePerAsset,
        mostPopular,
        fixed,
    } = params;

    const totalUpfront = upfrontPerAsset * assets;
    const totalUpfrontStr = numberWithCommas(totalUpfront);

    return (
        <div className="pb-10 xl:pt-8 xl:pb-10 text-center w-[425px]">
            {mostPopular ? (
                <MostPopular />
            ) : (
                <div className={"mb-8 xl:mb-[58px]"}></div>
            )}
            <Promo />

            <div className="flex flex-col items-center justify-center pb-10 pt-8 border-4 border-[#92D050] shadow-xl px-4 mx-4 xl:px-6 xl:mx-2">
                <div className="text-xl text-black pb-3">
                    <p className="text-2xl font-bold pb-4">{title}</p>
                    {description.map((desc, index) => (
                        <p key={index}>{desc}</p>
                    ))}
                </div>

                <Divider className="mt-3" />

                <Inputs
                    assets={assets}
                    setAssets={setAssets}
                />

                <Costs
                    licensePerAsset={licensePerAsset}
                    assets={assets}
                    fixed={fixed}
                />

                <Divider className="mt-3" />

                <GradientLinkButton
                    className="mt-10 mb-10"
                    to="/start"
                    text="Start Saving"
                />

                {tagline.map((line, index) => (
                    <p className="text-sm" key={index}>{line}</p>
                ))}
                <p className="text-sm mt-4">*Total upfront installation cost of ${totalUpfrontStr} applies.</p>
            </div>
        </div>
    );
};

export const Pricing: FC = () => {
    return (
        <>
            <div className="flex w-full items-center">
                <div className="text-center w-full py-10">
                    <div className="font-bold text-[#92D050] mb-6">
                        <Header
                            className="mb-4 px-10"
                            text="Save $1000's on your business energy bills"
                        />
                    </div>
                    <div className="text-2xl mb-4 px-10">
                        <p>
                            Subscribe to Raasify energy for refrigeration systems
                        </p>
                        <p className="mt-0">
                            and vending machines and{" "}
                            <span className="text-[#92D050]">
                                pay from your savings.
                            </span>
                        </p>
                    </div>
                    <div className="flex flex-wrap xl:flex-nowrap justify-center xl:mx-4">
                        <Product
                            title="Energy Monitor"
                            description={[
                                "Monitor and alert on asset power consumption to see which are using the most power or might be faulty.",
                            ]}
                            tagline={[
                                "* Price in AUD. One monitor per asset. Client",
                                "provided reliable Internet access. Two months free",
                                "applies to reccuring costs only."
                            ]}
                            upfrontPerAsset={0}
                            licensePerAsset={4}
                        />
                        <Product
                            title="Energy Saver"
                            description={[
                                "Align assets power consumption with business operating hours to maximize energy savings.",
                            ]}
                            tagline={[
                                "* Price in AUD. One monitor per asset. Client",
                                "provided reliable Internet access. Two months free",
                                "applies to reccuring costs only."
                            ]}
                            upfrontPerAsset={0}
                            licensePerAsset={5}
                            mostPopular
                        />
                        <Product
                            title="Energy Optimizer"
                            description={[
                                "Control asset temperature while maximizing power consumption to optimize energy savings.",
                            ]}
                            tagline={[
                                "* Price in AUD. Two monitors per asset. Gateway",
                                "included. Client provided Internet access. Two",
                                " months free applies to reccuring costs only."
                            ]}
                            upfrontPerAsset={70}
                            licensePerAsset={10}
                            fixed={200}
                        />
                    </div>
                </div>
            </div>
            <div className="flex flex-col w-full bg-[#F2F2F2] items-center pt-16">
                <Header
                    className="text-3xl font-bold text-[#92D050]"
                    text="Features Summary"
                />
                <Comparison />
            </div>
        </>
    );
};

