import { FC } from "react";
import { GradientLinkButton } from "./Buttons";
import { Header } from "./Header";
import { scrollToBottom } from "../util/util";

export const NoContracts: FC = () => {
    return (
        <div className="px-12 py-16 xl:pt-6 xl:pb-0 text-center">
            <Header
                text="No lock-in + Simple installation = You saving"
            />
            <div className="flex flex-col xl:flex-row w-full items-center justify-center py-10">
                <GradientLinkButton
                    to="/estimate"
                    text="Estimate Savings"
                    className="xl:mr-10"
                />
                <GradientLinkButton
                    className="mt-10 xl:mt-0 xl:ml-10"
                    to="/start"
                    text="Start Saving"
                />
            </div>
        </div>
    );
}
