import { FC } from "react";

interface ErrorMessageProps {
    message: string;
}

export const ErrorMessage: FC<ErrorMessageProps> = (props: ErrorMessageProps) => {
    const { message } = props;
    return (
        <div className="absolute mt-1 text-sm text-red-700 font-normal">{message}</div>
    )
}