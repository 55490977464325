import { FC } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

interface AlertDialogProps {
    alertMessage: string | undefined;
    setOpen: (open: boolean) => void;
    open: boolean;
    done?: boolean;
}

export const AlertDialog: FC<AlertDialogProps> = (props: AlertDialogProps) => {
    const { alertMessage, setOpen, open, done } = props;

    const handleClose = () => {
        setOpen(false)
    };

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <p className="pt-2 px-4">
                    {done ? "Success" : "Validation Error"}
                </p>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" className="pt-2 px-4">
                    {alertMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <div className="mb-2 px-2">
                    <Button onClick={handleClose} autoFocus>OK</Button>
                </div>
            </DialogActions>
        </Dialog>
    );
}
