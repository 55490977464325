import { FC, useState } from "react";
import { Header } from "./Header";
import { IconButton, Tooltip } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import { GradientButton } from "./Buttons";
import { cn, numberWithCommas, numberWithCommasAnd2Decimals } from "../util/util";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as React from 'react';
import { toast } from "react-toastify";
import { HourSelector } from "./HourSelector";
import { ErrorMessage } from "./ErrorMessage";
import { ConfirmDialog } from "./ConfirmDialog";
import { AlertDialog } from "./AlertDialog";
import ReCAPTCHA from 'react-google-recaptcha'
import { REACT_APP_SITE_KEY } from "../util/settings";

const BUTTON_SELECTED_CLASS = "bg-white border-blue-700 border-4";
const BUTTON_NOT_SELECTED_CLASS = "bg-white";

const FRIDGE_WATTS = 100;
const VENDING_WATTS = 128;
const OTHER_WATTS = 349;

interface SelectedIF {
    open: string;
    close: string;
    fridge: number;
    vending: number;
    other: number;
    price: number;
    failures: number;
    timeToRepair: number;
    email?: string;
}

interface AssetLabelProps {
    asset: string;
    icon: string;
}

export const AssetLabel: FC<AssetLabelProps> = (props: AssetLabelProps) => {
    const { asset, icon } = props;
    return (
        <div className={cn("h-14 w-32 text-black text-xs p-2  font-normal flex items-center rounded-2xl cursor-pointer bg-white")}>
            <img src={icon} className="w-4 object-cover cursor-pointer" />
            <label className="ml-2 cursor-pointer">{asset}</label>
        </div>
    )
}

interface FailureSelectionProps {
    selected?: number;
    onSelect: (value: number) => void;
}

export const FailureSelection: FC<FailureSelectionProps> = (props: FailureSelectionProps) => {
    const { selected, onSelect } = props;
    const bgClass0 = selected === 0 ? BUTTON_SELECTED_CLASS : BUTTON_NOT_SELECTED_CLASS;
    const bgClass1 = selected === 1 ? BUTTON_SELECTED_CLASS : BUTTON_NOT_SELECTED_CLASS;
    const bgClass2 = selected === 2 ? BUTTON_SELECTED_CLASS : BUTTON_NOT_SELECTED_CLASS;
    const bgClass3 = selected === 3 ? BUTTON_SELECTED_CLASS : BUTTON_NOT_SELECTED_CLASS;

    const baseClasses = "h-18 w-20 xl:w-36 text-black text-sm p-2 py-4 font-normal flex items-center rounded-2xl cursor-pointer";
    return (
        <div className="flex gap-4">
            <div className={cn(baseClasses, bgClass0)} onClick={() => onSelect(0)}>
                <div className="hidden xl:flex">
                    <img src="/images/icon-fix.svg" className="w-8 object-cover cursor-pointer" />
                </div>
                <label className="xl:ml-4 w-full text-center xl:text-left cursor-pointer">None</label>
            </div>
            <div className={cn(baseClasses, bgClass1)} onClick={() => onSelect(1)}>
                <div className="hidden xl:flex">
                    <img src="/images/icon-fix.svg" className="w-8 object-cover cursor-pointer" />
                </div>
                <label className="xl:ml-4 w-full text-center xl:text-left cursor-pointer">One</label>
            </div>
            <div className={cn(baseClasses, bgClass2)} onClick={() => onSelect(2)}>
                <div className="hidden xl:flex">
                    <img src="/images/icon-fix.svg" className="w-8 object-cover cursor-pointer" />
                </div>
                <label className="xl:ml-4 w-full text-center xl:text-left cursor-pointer">Two</label>
            </div>
            <div className={cn(baseClasses, bgClass3)} onClick={() => onSelect(3)}>
                <div className="hidden xl:flex">
                    <img src="/images/icon-fix.svg" className="w-8 object-cover cursor-pointer" />
                </div>
                <label className="xl:ml-4 w-full text-center xl:text-left cursor-pointer">Three+</label>
            </div>
        </div>
    )
}

interface TimeToRepairSelectionProps {
    selected?: number;
    onSelect: (value: number) => void;
}

export const TimeToRepairSelection: FC<TimeToRepairSelectionProps> = (props: TimeToRepairSelectionProps) => {
    const { selected, onSelect } = props;
    const bgClass1 = selected === 1 ? BUTTON_SELECTED_CLASS : BUTTON_NOT_SELECTED_CLASS;
    const bgClass2 = selected === 2 ? BUTTON_SELECTED_CLASS : BUTTON_NOT_SELECTED_CLASS;
    const bgClass4 = selected === 4 ? BUTTON_SELECTED_CLASS : BUTTON_NOT_SELECTED_CLASS;

    const baseClasses = "h-18 w-28 xl:w-36 text-black text-sm p-2 py-4 font-normal flex items-center rounded-2xl cursor-pointer";
    return (
        <div className="flex gap-4">
            <div className={cn(baseClasses, bgClass1)} onClick={() => onSelect(1)}>
                <div className="hidden xl:flex">
                    <img src="/images/icon-fix.svg" className="w-8 object-cover cursor-pointer" />
                </div>
                <label className="xl:ml-4 w-full text-center xl:text-left cursor-pointer">1 day</label>
            </div>
            <div className={cn(baseClasses, bgClass2)} onClick={() => onSelect(2)}>
                <div className="hidden xl:flex">
                    <img src="/images/icon-fix.svg" className="w-8 object-cover cursor-pointer" />
                </div>
                <label className="xl:ml-4 w-full text-center xl:text-left cursor-pointer">2-3 days</label>
            </div>
            <div className={cn(baseClasses, bgClass4)} onClick={() => onSelect(4)}>
                <div className="hidden xl:flex">
                    <img src="/images/icon-fix.svg" className="w-8 object-cover cursor-pointer" />
                </div>
                <label className="xl:ml-4 w-full text-center xl:text-left cursor-pointer">4 or more</label>
            </div>
        </div>
    )
}

interface PageProps {
    selected?: SelectedIF;
    setSelected?: (selected: SelectedIF) => void;
    onCancel?: () => void;
    onNext?: () => void;
    onBack?: () => void;
    onSubmit?: () => void;
    setToken?: (token: string) => void;
    token?: string;
    results?: ResultsIF;
    submitted?: boolean;
    debug?: boolean;
}

const Page1: FC<PageProps> = (props: PageProps) => {
    const { onCancel, onNext, selected, setSelected, debug } = props;
    const [errors, setErrors] = useState<any>({});

    if (!selected || !setSelected) {
        return <></>
    }

    const validate = () => {
        if (debug) return true;
        let ret = true;
        let errorRes = {};
        if (selected.fridge + selected.vending + selected.other === 0) {
            errorRes = { ...errorRes, assets: { message: "Please select at least one asset type" } };
            ret = false;
        }
        if (!selected.price) {
            errorRes = { ...errorRes, price: { message: "Please enter your off-peak per kWh unit price" } };
            ret = false;
        }
        setErrors(errorRes);
        return ret;
    }

    return (
        <>
            <div className="flex w-full relative">
                <div className="w-[40%] px-10 py-10 hidden xl:flex flex-col justify-center items-center">
                    <div className="w-full flex flex-col items-center">
                        <img src="/images/raasify.svg" className="w-[220px]" />
                        <img src="/images/start-saving.png" className="mt-10 w-full" />
                    </div>
                </div>
                <div className="w-full xl:w-[60%] min-h-[100vh] h-full px-4 xl:px-20 py-10 bg-[#92D050] flex flex-col justify-center items-center">
                    <div className="w-full">
                        <div className="absolute right-4 top-4 text-white">1 of 2</div>
                        <div className="font-bold mb-6">
                            <Header
                                className="mb-4 text-white"
                                text="ESTIMATE MY SAVINGS"
                            />
                        </div>
                        <p className="mb-4 text-white">The Raasify energy solution for refrigeration systems and vending machines, helps businesses reduce their monthly electricity bills, increase the efficiency and longevity of these assets, while minimizing the potential for ongoing expensive technical repair works.</p>
                        <div className="text-white text-lg font-bold">
                            <p className="mb-4">What are your opening hours?</p>
                            <div className="flex flex-col xl:flex-row mt-8 mb-4 xl:items-center gap-8">
                                <div className="flex gap-2 items-center ">
                                    <p className="w-[100px]">I open at:</p>
                                    <HourSelector selected={selected.open} setSelected={(value) => setSelected({ ...selected, open: value })} />
                                </div>
                                <div className="flex gap-2 items-center ">
                                    <p className="w-[100px]">I close at:</p>
                                    <HourSelector selected={selected.close} setSelected={(value) => setSelected({ ...selected, close: value })} />
                                </div>
                            </div>
                        </div>
                        <div className="text-white text-lg font-bold">
                            <div className="flex mt-6 mb-2">
                                <p className="mt-1.5">How many of each asset type, do you have?</p>
                                <Tooltip title="Standalone powered assets that can be turned off and on during their operational lifecycle.">
                                    <IconButton>
                                        <InfoIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <div className="flex flex-col xl:flex-row xl:grid-flow-row xl:grid-cols-3 xl:space-x-6 space-y-4 xl:space-y-0">
                                <div className="flex">
                                    <AssetLabel
                                        icon="/images/icon-fridge.png"
                                        asset="Refrigeration systems"
                                    />
                                    <input
                                        value={selected.fridge}
                                        type="number"
                                        min="0"
                                        className="text-center ml-2 h-14 rounded-xl w-16 text-black"
                                        onChange={(e) => setSelected({ ...selected, fridge: parseInt(e.target.value) })}
                                    />
                                </div>
                                <div className="flex">
                                    <AssetLabel
                                        icon="/images/icon-fridge.png"
                                        asset="Vending machines"
                                    />
                                    <input
                                        value={selected.vending}
                                        type="number"
                                        min="0"
                                        className="text-center ml-2 h-14 rounded-xl w-16 text-black"
                                        onChange={(e) => setSelected({ ...selected, vending: parseInt(e.target.value) })}
                                    />
                                </div>
                                <div className="flex">
                                    <AssetLabel
                                        icon="/images/icon-other.png"
                                        asset="Other asset types"
                                    />
                                    <input
                                        type="text"
                                        placeholder="N/A"
                                        min="0"
                                        disabled
                                        className="disabled:bg-white text-center p-4 ml-2 h-14 rounded-xl w-16 text-black"
                                        onChange={(e) => setSelected({ ...selected, other: parseInt(e.target.value) })} />
                                </div>
                            </div>
                            <ErrorMessage message={errors.assets?.message} />
                        </div>
                        <div className="text-white text-lg font-bold">
                            <div className="flex mt-6 mb-2 items-center">
                                <p className="">What is your <u><i>off-peak</i></u> per kWh unit price?</p>
                                <Tooltip title="This value is listed on your monthly electricity bill.">
                                    <IconButton>
                                        <InfoIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <div className="flex relative items-center">
                                <input
                                    value={selected.price}
                                    onChange={(e) => setSelected({ ...selected, price: parseFloat(e.target.value) || 0 })}
                                    type="number"
                                    className="pl-6 border-2 border-[#92D050] w-100 h-10 rounded-md px-2 text-black" />
                                <p className="pl-2 absolute text-black">$</p>
                            </div>
                        </div>
                        <ErrorMessage message={errors.price?.message} />
                        <GradientButton
                            className="mt-8 float-right w-40 xl:w-48"
                            onClick={() => {
                                if (validate()) onNext && onNext();
                            }}
                            text="Next"
                        />
                        <GradientButton
                            className="mt-8 w-40 xl:w-48"
                            onClick={onCancel}
                            text="Cancel"
                        />
                    </div>
                </div>
            </div>

        </>
    );
};

const Page2: FC<PageProps> = (props: PageProps) => {
    const { onBack, onNext, selected, setSelected, debug } = props;
    const [errors, setErrors] = useState<any>({});

    if (!selected || !setSelected) {
        return <></>
    }

    const validate = () => {
        if (debug) return true;
        let ret = true;
        let errorRes = {};
        if (!selected.fridge && !selected.vending && !selected.other) {
            errorRes = { ...errorRes, assets: { message: "Please select at least one asset type" } };
            ret = false;
        }
        if (!selected.price) {
            errorRes = { ...errorRes, price: { message: "Please enter your off-peak per kWh unit price" } };
            ret = false;
        }
        setErrors(errorRes);
        return ret;
    }

    return (
        <>
            <div className="flex w-full relative">
                <div className="w-[40%] px-10 py-10 hidden xl:flex flex-col justify-center items-center">
                    <div className="w-full flex flex-col items-center">
                        <img src="/images/raasify.svg" className="w-[220px]" />
                        <img src="/images/new-way.png" className="mt-10 w-[25vw]" />
                    </div>
                </div>
                <div className="w-full xl:w-[60%] min-h-[100vh] h-full px-4 xl:px-20 py-10 bg-[#92D050] flex flex-col justify-center items-center">
                    <div className="w-full">
                        <div className="absolute right-4 top-4 text-white">2 of 2</div>
                        <div className="font-bold mb-6">
                            <Header
                                className="mb-8 text-white"
                                text="ESTIMATE MY SAVINGS"
                            />
                        </div>
                        <p className="mb-4 text-white">The Raasify energy solution also helps businesses increase the efficiency and longevity of their assets, while minimizing the potential for ongoing expensive technical repair works.</p>
                        <div className="text-white text-lg font-bold">
                            <div className="flex mt-8 mb-4">
                                <p className="mt-1.5">Of the listed assets, on average how many experience failure in <u><i>one</i></u> year?</p>
                            </div>
                            <div className="flex grid-flow-row grid-cols-3 space-x-1 xl:space-x-4">
                                <FailureSelection
                                    selected={selected.failures}
                                    onSelect={(value) => setSelected({ ...selected, failures: value })}
                                />
                            </div>
                        </div>
                        <div className="text-white text-lg font-bold">
                            <div className="flex mt-8 mb-4">
                                <p className="mt-1.5">On average, how long does it take fix these assets?</p>
                            </div>
                            <div className="flex grid-flow-row grid-cols-3 space-x-1 xl:space-x-4">
                                <TimeToRepairSelection
                                    selected={selected.timeToRepair}
                                    onSelect={(value) => setSelected({ ...selected, timeToRepair: value })}
                                />
                            </div>
                        </div>
                        <GradientButton
                            className="mt-16 float-right w-40 xl:w-48"
                            onClick={() => {
                                if (validate()) onNext && onNext();
                            }}
                            text="Next"
                        />
                        <GradientButton
                            className="mt-16 w-40 xl:w-48"
                            onClick={onBack}
                            text="Back"
                        />
                    </div>
                </div>
            </div>

        </>
    );
};


interface ResultProps {
    header: string;
    value: number;
    className?: string;
    prefix?: string;
    suffix?: string;
    processor?: (value: number) => string;
    larger?: boolean;
}

const Result: FC<ResultProps> = (props: ResultProps) => {
    const { header, value, className, prefix, suffix, processor, larger } = props

    const result = processor ? processor(value) : value;

    const widthClass = larger ? "w-48 xl:w-48" : "w-36 xl:w-48";

    return (
        <div className={cn("flex flex-col items-center rounded-xl h-[80px] bg-white m-2", widthClass, className || '')}>
            <p className={"text-black font-bold mt-3 mb-1 text-sm"}>{header}</p>
            <p className={"text-[#92D050] font-bold text-2xl"}>{prefix}{result}{suffix}</p>
        </div>
    )
}


const Page3: FC<PageProps> = (props: PageProps) => {
    const { onBack, onNext, selected, setSelected, setToken, token, results, onSubmit, submitted, debug } = props;
    const [errors, setErrors] = useState<any>({});

    if (!selected || !setSelected || !setToken || !onNext || !onBack) {
        return <></>
    }

    const validate = () => {
        if (debug) return true;
        let ret = true;
        let errorRes = {};
        if (!selected.email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(selected.email)) {
            errorRes = { ...errorRes, email: { message: "Please enter a valid email address" } };
            ret = false;
        }
        if (!token) {
            errorRes = { ...errorRes, token: { message: "Please verify the Recaptcha" } };
            ret = false;
        }
        setErrors(errorRes);
        return ret;
    }

    const onVerifyCaptcha = (token: string) => {
        setToken(token);
    };

    return (
        <>
            <div className="flex w-full relative">
                <div className="w-[40%] px-10 py-10 hidden xl:flex flex-col justify-center items-center">
                    <div className="w-full flex flex-col items-center">
                        <img src="/images/raasify.svg" className="w-[220px]" />
                        <p className="mt-12 text-[#92D050] text-4xl font-bold">Thank you!</p>
                        <p className="mt-12 text-[#92D050] text-lg font-bold text-center">Let us know your email so we can send you your saving estimates.</p>
                    </div>
                </div>
                <div className="w-full xl:w-[60%] min-h-[100vh] h-full px-4 xl:px-20 py-10 bg-[#92D050] flex flex-col justify-center items-center">
                    <div className="w-full relative">
                        <div className="font-bold mb-2">
                            <Header
                                className="mb-1 text-white"
                                text="YOUR ESTIMATED SAVINGS"
                            />
                        </div>
                        <p className="text-sm text-white mb-6">Savings presented as a guide and may vary per deployment</p>
                        <p className="mb-4 text-white font-bold">Your estimated annual savings achieved by deploying the Raasify energy saver plan for refrigeration systems and vending machines is:</p>

                        <div className="hidden xl:flex flex-col justify-center items-center">
                            <div className="flex">
                                <Result header="Electricity bill ($)" value={results?.electricityBill as number} prefix="$" processor={numberWithCommas} />
                                <Result header="Maintenance ($)" value={results?.maintenance as number} prefix="$" processor={numberWithCommas} />
                                <Result header="Total Savings ($)" value={results?.totalSavings as number} prefix="$" processor={numberWithCommas} />
                            </div>
                            <div className="flex">
                                <Result larger header="Electricity usage (kWh)" value={results?.electricityUsage as number} processor={numberWithCommas} />
                                <Result larger header="Carbon footprint (CO2)" value={results?.carbonFootprint as number} suffix="t" processor={numberWithCommasAnd2Decimals} />
                            </div>
                        </div>

                        <div className="flex xl:hidden flex-col justify-center items-center">
                            <div className="flex">
                                <Result larger header="Electricity bill ($)" value={results?.electricityBill as number} prefix="$" processor={numberWithCommas} />
                            </div>
                            <div className="flex">
                                <Result larger header="Maintenance ($)" value={results?.maintenance as number} prefix="$" processor={numberWithCommas} />
                            </div>
                            <div className="flex">
                                <Result larger header="Total Savings ($)" value={results?.totalSavings as number} prefix="$" processor={numberWithCommas} />
                            </div>
                            <div className="flex">
                                <Result larger header="Electricity usage (kWh)" value={results?.electricityUsage as number} processor={numberWithCommas} />
                            </div>
                            <div className="flex">
                                <Result larger header="Carbon footprint (CO2)" value={results?.carbonFootprint as number} suffix="t" processor={numberWithCommasAnd2Decimals} />
                            </div>
                        </div>


                        <div className="text-white text-lg font-bold mb-8">
                            <p className="mt-2 mb-1">Email me my estimated savings</p>
                            <div className="flex flex-row">
                                <input
                                    value={selected.email}
                                    onChange={(e) => setSelected({ ...selected, email: e.target.value })}
                                    type="email"
                                    className="border-2 border-[#92D050] w-full h-10 rounded-md px-2 text-black" />
                            </div>
                            <ErrorMessage message={errors.email?.message} />
                        </div>
                        <ReCAPTCHA className="mt-2" sitekey={REACT_APP_SITE_KEY} onChange={onVerifyCaptcha as any} />
                        <ErrorMessage message={errors.token?.message} />
                        <div className="flex gap-4 mt-10">
                            <GradientButton
                                onClick={onBack}
                                text="Back"
                                className="w-[120px] xl:w-[200px]"
                            />
                            {!submitted && <GradientButton
                                className="ml-32 w-[120px] xl:w-[200px]"
                                onClick={() => {
                                    if (validate()) onSubmit && onSubmit();
                                }}
                                text="Email"
                            />}
                            {submitted && <GradientButton
                                className="ml-32 w-[120px] xl:w-[200px]"
                                disabled
                                text="Sent!"
                            />}
                            <GradientButton
                                onClick={onNext}
                                text="Done"
                                className="w-[120px] xl:w-[200px]"
                            />
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

const getClosedMinutes = (open: string, close: string) => {
    const openParts = open.split(":");
    const closeParts = close.split(":");
    const openTime = parseInt(openParts[0]) * 60 + parseInt(openParts[1]);
    const closeTime = parseInt(closeParts[0]) * 60 + parseInt(closeParts[1]);
    return 1440 - (closeTime - openTime);
}

interface ResultsIF {
    electricityBill: number;
    maintenance: number;
    totalSavings: number;
    electricityUsage: number;
    carbonFootprint: number;
}

export const Estimate: FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const debug = searchParams.get("debug") ? true : false;

    const [alertOpen, setAlertOpen] = React.useState(false);
    const [confirmOpen, setConfirmOpen] = React.useState(false);
    const [confirmMessage, setConfirmMessage] = useState<string | undefined>();
    const [done, setDone] = React.useState(false);
    const [alertMessage, setAlertMessage] = useState<string | undefined>();
    const [page, setPage] = useState(1);
    const [selected, setSelected] = useState({ open: "09:00", close: "17:00", failures: 1, timeToRepair: 2, fridge: 0, vending: 0, other: 0, price: 0.3 } as SelectedIF);
    const [token, setToken] = useState<string | undefined>();
    const [results, setResults] = useState<ResultsIF>();
    const navigate = useNavigate();
    const [submitted, setSubmitted] = useState(false);

    const onCancel = () => {
        setConfirmMessage("Are you sure you want to abort?");
        setConfirmOpen(true);
    }

    const onNext = () => {
        setPage(page + 1);
    }

    const onCalculate = () => {
        const closedMinutes = getClosedMinutes(selected.open, selected.close);
        let closedHours = closedMinutes / 60;
        closedHours = closedHours < 1 ? 0 : closedHours - 1;

        const fridgeWatts = FRIDGE_WATTS * selected.fridge;
        const vendingWatts = VENDING_WATTS * selected.vending;
        const otherWatts = OTHER_WATTS * selected.other;

        const totalWatts = fridgeWatts + vendingWatts + otherWatts;
        const kWhPerDay = totalWatts / 1000 * closedHours;

        const electricityUsage = kWhPerDay * 365;
        const electricityBill = electricityUsage * selected.price;

        const maintenance = selected.failures * 1050;
        const carbonFootprint = electricityUsage * 0.00077;
        const totalSavings = electricityBill + maintenance;

        setResults({ electricityUsage, electricityBill, maintenance, totalSavings, carbonFootprint });
        setPage(page + 1);
    }

    const onBack = () => {
        setPage(page - 1);
    }

    // Submit the results via email
    const onSubmit = async () => {
        const { email } = selected;

        const message = `
Hi there,

Thank you for using the Raasify energy saver calculator for refrigeration systems and vending machines.

Your estimated annual savings achieved by deploying the Raasify energy saver plan for refrigeration systems and vending machines are:
Electricity bill ($): $${numberWithCommas(results?.electricityBill as number)}
Maintenance ($): $${numberWithCommas(results?.maintenance as number)}
Total Savings ($): $${numberWithCommas(results?.totalSavings as number)}
Electricity usage (kWh): ${numberWithCommas(results?.electricityUsage as number)}
Carbon footprint (CO2): ${numberWithCommasAnd2Decimals(results?.carbonFootprint as number)}t

Your inputs were:
Fridges: ${selected.fridge}
Vending machines: ${selected.vending}
Price per kWh: ${selected.price}
Failures: ${selected.failures}
Time to repair: ${selected.timeToRepair}

We'll be in touch shortly to discuss how we can help you save on your energy bills.

Best regards,

The Raasify Team



You received this email because we thought you would like to know more about Raasify. If you no longer wish to receive these emails, just let us know by replying to this email.
Raasify Inc. All rights reserved. 2024
`;

        const response = await fetch('/api/v1/estimate', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, name: email, message, token }),
        });
        console.log({ message, response });

        if (response.status !== 200) {
            toast.error("Error submitting form. Please try again.")
        }
        else {
            setSubmitted(true);
        }
    }

    const handleSetAlertOpen = (open: boolean) => {
        setAlertOpen(open);
        if (!open) navigate("/");
    }

    const handleSetConfirmSuccess = (success: boolean) => {
        setConfirmOpen(false);
        if (success) navigate("/");
    }

    const onReturn = () => {
        navigate("/");
    }

    return (
        <>
            <ConfirmDialog confirmMessage={confirmMessage} open={confirmOpen} setSuccess={handleSetConfirmSuccess} />
            <AlertDialog alertMessage={alertMessage} open={alertOpen} setOpen={handleSetAlertOpen} done={done} />
            {page === 1 &&
                <Page1
                    debug={debug}
                    onCancel={onCancel}
                    onNext={onNext}
                    selected={selected}
                    setSelected={setSelected}
                />
            }
            {page === 2 &&
                <Page2
                    debug={debug}
                    onBack={onBack}
                    onNext={onCalculate}
                    selected={selected}
                    setSelected={setSelected}
                />
            }
            {page === 3 &&
                <Page3
                    debug={debug}
                    onBack={onBack}
                    onNext={onReturn}
                    onSubmit={onSubmit}
                    selected={selected}
                    setSelected={setSelected}
                    token={token}
                    setToken={setToken}
                    results={results}
                    submitted={submitted}
                />
            }
        </>
    )
};
