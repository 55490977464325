import { FC } from "react";
import { scrollToBottom } from "../util/util";
import { GradientLinkButton } from "./Buttons";
import { Header } from "./Header";

export const TypicalBusinessCanExpect: FC = () => {
    return (
        <div className="flex flex-col xl:flex-row justify-center w-full px-16 xl:px-32 xl:py-12 text-center">
            <div className="flex flex-col justify-center w-full xl:w-[65%] xl:pr-12 pt-6 xl:text-left pb-20">
                <Header
                    text="What a typical business can expect?"
                    className="mt-14"
                />
                <p className="text-xl mt-10">
                    The Raasify energy solution helps businesses reduce their monthly electricity bills, increase the efficiency and longevity of their assets, while minimizing the potential for ongoing expensive technical repair works.
                </p>
                <p className="text-xl mt-5">
                    Unlike other solutions, you don't have to wait months to realize savings. Sign-up to Raasify energy and start seeing recurring savings from the first month.
                </p>
                <div className="mt-14 flex flex-col w-full justify-center">
                    <GradientLinkButton
                        to="/estimate"
                        text="Estimate Savings"
                        className="xl:mt-10 self-center xl:self-start"
                    />
                    <GradientLinkButton
                        className="mt-10 self-center xl:self-start"
                        to="/start"
                        text="Start Saving"
                    />
                </div>
            </div>
            <div className="xl:w-[35%] xl:relative flex flex-col w-full justify-center">
                <img src="/images/saving.png" alt="typical business" className="self-center xl:my-20 mb-20 xl:mb-40 w-[400px]" />
                <img src="/images/chart.png" alt="typical business" className="absolute bottom-20 -left-60 w-[400px] hidden xl:inline" />
            </div>
        </div>

    );

}
